import Image from "next/image"
import {
  CardPin,
  CardPinIconContainer,
  StyledVacancyCard,
  VacancyCVHoverButton
} from "src/views/components/Cards/Card.styles"
import Typography from "../typography"

import { cx } from "class-variance-authority"
import { useContext } from "react"
import { SwiperSlide } from "swiper/react"
import { CompanyImage, VacancyBody, VacancyCardHeader, VacancyCardHeaderDesc } from "./Card.styles"

import Link from "next/link"
import { ResumeApplyContext } from "src/context/ResumeApplyProvider"
import useIndex from "src/server/queryHooks/useIndex"
import useVacancy from "src/server/queryHooks/useVacancy"
import { getEmploymenTypeLabel, getJobTypeLabel } from "src/types/vacancy/workOptions"
import CompanyLogo from "src/views/components/GeneralLogo/CompanyLogo"
import { twMerge } from "tailwind-merge"
import Spinner from "src/views/components/UI/loaders/Spinner"
import { convertSalaryVariantsToText } from "src/utils/helpers/string/convertSalaryVariantsToText"
import { renderVacancySalaryPeriod } from "src/utils/helpers/vacancy/renderVacancySalaryPeriod"
import { useTranslation } from "next-i18next"

const VacancyCard = ({
  id,
  companyName,
  country,
  city,
  title,
  range,
  salary_period,
  job_type,
  employment_type,
  has_logo,
  company_id,
  logo_version,
  benefits = [],
  vacancy,
  openVacancy = () => {}
}: any) => {
  const { t } = useTranslation("common")
  const { setVacancyId } = useContext(ResumeApplyContext)
  const { benefitsQuery } = useIndex()
  const { saveVacancyMutation, deleteSavedVacancyMutation, vacancySavedAppliedStatus } = useVacancy()
  const isSaved = vacancySavedAppliedStatus.data?.saved[id]
  const isApllied = vacancySavedAppliedStatus.data?.applied[id]

  const saveVacancy = () => {
    if (!saveVacancyMutation.isLoading && !deleteSavedVacancyMutation.isLoading) {
      isSaved ? deleteSavedVacancyMutation.mutate(id) : saveVacancyMutation.mutate(id)
    }
  }

  const styledVacancy = () => {
    return (
      <StyledVacancyCard>
        <VacancyCardHeader onClick={() => window.innerWidth < 736 && openVacancy(vacancy)}>
          <CompanyImage>
            <CompanyLogo has_logo={has_logo} logo_version={logo_version} company_id={company_id} />
          </CompanyImage>
          <VacancyCardHeaderDesc className="overflow-hidden">
            <Typography type="text" className="line-clamp-1">
              {companyName}
            </Typography>
            <Typography type="mini">
              {city ? `${city},` : ""} {country}
            </Typography>
          </VacancyCardHeaderDesc>
        </VacancyCardHeader>

        <VacancyBody onClick={() => window.innerWidth < 736 && openVacancy(vacancy)}>
          <Typography type="h5" weight="bold" className="line-clamp-2 h-13 leading-relaxed">
            {title}
          </Typography>

          <CardPin>
            {range !== "შეთანხმებით" ? (
              <CardPinIconContainer>
                <Image src="/images/lari.svg" width={14} height={16} alt="lari" />
              </CardPinIconContainer>
            ) : (
              ""
            )}
            <Typography type="h5" weight="medium">
              {range}
              <Typography type="small" className="ml-1">
                {salary_period}
              </Typography>
            </Typography>
          </CardPin>

          <CardPin>
            <CardPinIconContainer>
              <Image src="/images/watch_later.svg" width={20} height={20} alt="watch_later" />
            </CardPinIconContainer>
            <Typography type="small">{t(getEmploymenTypeLabel(employment_type))}</Typography>
          </CardPin>
          <CardPin>
            <CardPinIconContainer>
              <Image src="/images/work_outline.svg" width={20} height={20} alt="work_outline" />
            </CardPinIconContainer>
            <Typography type="small">{t(getJobTypeLabel(job_type))}</Typography>
          </CardPin>
          <div className="flex items-center justify-between">
            <div className="line-clamp-1 flex items-center gap-3">
              {benefits.slice(0, 2).map(({ title, id }: any) => {
                const currentBenefit = benefitsQuery.data?.find(benefit => benefit.id === id)
                if (!currentBenefit) return null
                return (
                  <CardPin key={id}>
                    {/* {benefits.find((benefit: any) => benefit.id === id)?.icon && (} */}
                    <RenderBenefitSvg icon={currentBenefit.icon} id={currentBenefit.id} />
                    <Typography type="small" className="line-clamp-1">
                      {title}
                    </Typography>
                  </CardPin>
                )
              })}
            </div>
            <Typography type="text" className="ml-2 shrink-0" weight="medium">
              {benefits.length > 2 && `+ ${benefits.length - 2}`}
            </Typography>
          </div>
        </VacancyBody>

        <VacancyCVHoverButton className="vacancyHoverButton">
          <div
            onClick={e => {
              e.preventDefault()
              if (!isApllied) {
                setVacancyId(id)
              }
            }}
            className={twMerge(
              cx(
                "flex-1 cursor-pointer rounded-full bg-secondary-100 px-5 py-3 text-center transition-all hover:bg-secondary-90",
                isApllied ? "bg-secondary-40 hover:bg-secondary-40" : ""
              )
            )}
          >
            <Typography type="button" weight="medium" color="light">
              {isApllied ? t("SENT") : t("SEND")}
            </Typography>
          </div>

          <div
            onClick={e => {
              e.preventDefault()
              saveVacancy()
            }}
            className={cx(
              "flex h-[44px] w-[44px] cursor-pointer items-center justify-center rounded-full transition-all",
              isSaved ? "bg-primary-5 hover:bg-primary-10" : "bg-neutral-10  hover:bg-neutral-20"
              // Cookie.get(ACCESS_TOKEN_NAME) ? 'visible' : 'hidden'
            )}
          >
            {saveVacancyMutation.isLoading || deleteSavedVacancyMutation.isLoading ? (
              <div>
                <Spinner />
              </div>
            ) : (
              <Image
                src={isSaved ? "/images/bookmark_border_selected.svg" : "/images/bookmark_border.svg"}
                width={14}
                height={18}
                alt="bookmark_border"
              />
            )}
          </div>
        </VacancyCVHoverButton>
      </StyledVacancyCard>
    )
  }

  return (
    <>
      {window.innerWidth < 736 ? (
        <div className="cursor-pointer">
          {styledVacancy()}
        </div>
      ) : (
        <Link href={`/vacancy/${id}`} target="_blank">
          {styledVacancy()}
        </Link>
      )}
    </>
  )
}
interface RenderBenefitSvgProps {
  icon: string
  id: number
}
const RenderBenefitSvg = ({ icon, id }: RenderBenefitSvgProps) => {
  // Modify the SVG's fill attribute before rendering
  // const modifiedIcon = icon.replace('#222222', selectedBenefits.includes(id) ? '#ffffff' : '#222222')

  // Also change fill inside the path

  // Render svg
  return <div dangerouslySetInnerHTML={{ __html: icon }} />
}

interface VacancyCardsProps {
  data: any[]
  type?: string
  openVacancy?: any
}
const VacancyCards = ({ data, type = "default", openVacancy = () => {} }: VacancyCardsProps) => {
  const { t } = useTranslation("common")
  return (
    <>
      {data.map((vacancy, index) => (
        <SwiperSlide className="top-vacancy-box" key={vacancy.id + vacancy.title + index}>
          <VacancyCard
            id={vacancy.id}
            index={index}
            companyName={vacancy.company && type === "similar" ? vacancy.company.brand_name : vacancy.company_name}
            country={vacancy.country && type === "similar" ? vacancy.country.title : vacancy.company_country_title}
            city={
              vacancy.company && vacancy.company.legal_city_title !== undefined && type === "similar"
                ? vacancy.company.legal_city_title
                : vacancy.company_city_title
            }
            title={vacancy.title}
            range={
              !vacancy.show_salary
                ? "შეთანხმებით"
                : convertSalaryVariantsToText(vacancy.salary_from, vacancy.salary_to, vacancy.salary_type)
            }
            salary_period={vacancy.salary_type != 'negotiable' && ('/' + t(vacancy.salary_period?.toUpperCase() || ""))}
            job_type={vacancy.job_type || "Unknown"} // Provide a default value for undefined job_type
            employment_type={vacancy.employment_type}
            benefits={vacancy.benefits}
            has_logo={vacancy.company && type === "similar" ? vacancy.company.has_logo : vacancy.company_has_logo}
            company_id={vacancy.company && type === "similar" ? vacancy.company.id : vacancy.company_id}
            logo_version={
              vacancy.company && type === "similar" ? vacancy.company.logo_version : vacancy.company_logo_version
            }
            vacancy={vacancy}
            openVacancy={openVacancy}
          />
        </SwiperSlide>
      ))}
    </>
  )
}
VacancyCards.displayName = "SwiperSlide"
export default VacancyCards
